<template>
  <v-container>
    <v-progress-circular
      :size="50"
      color="privat"
      indeterminate
    ></v-progress-circular>
    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import showSnack from "@/mixins/showSnack.mixins";
import errorHandler from "@/utils/errorHandler";

export default {
  name: "preloader",
  mixins: [showSnack],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUser", "getStatus"])
  },
  methods: {
    getEsaUser() {
      this.$store
        .dispatch("getEsaUserInfo")
        .then(() => {
          if (this.getUser?.ldap) {
            this.$router.replace("/orders");
          } else {
            let errorCode = this.getStatus || "ERROR_USER";

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("then getEsaUser error - ", errorCode);
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "ERROR_USER");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getEsaUser");
        });
    }
  },
  mounted() {
    this.getEsaUser();
  }
};
</script>
